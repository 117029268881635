import * as React from 'react';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import {
    Route,
    Routes,
} from "react-router-dom";
// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

import '../src/app.css';

import { navItems, capabilities, showcases, news } from './constants';
import Root from "./routes/root";
import ErrorPage from "./error_page";
import Welcome from './routes/welcome';

export const ColorModeContext = React.createContext({ toggleColorMode: () => { } });

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyAYfl94U0rCYUZLb4uKRXE6a0z5um78EFA",
    authDomain: "ultech-dev.firebaseapp.com",
    projectId: "ultech-dev",
    storageBucket: "ultech-dev.appspot.com",
    messagingSenderId: "350993804512",
    appId: "1:350993804512:web:689b1efe3b3f11d0e8f485",
    measurementId: "G-FE896L23MB"
};

// Initialize Firebase
initializeApp(firebaseConfig);
// const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);

const lightTheme = createTheme({
    palette: {
        mode: 'light',
        primary: {
            main: '#77536d',
        },
        secondary: {
            main: '#f50057',
        },
        background: {
            paper: '#e1e2e4',
            default: '#c0c8cd',
        },
    },
    typography: {
        fontFamily: [
            'ChakraPetch',
        ].join(','),
    },
});

const darkTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#bac3ff',
        },
        secondary: {
            main: '#ffb2ba',
        },
        background: {
            default: '#001f2a',
            paper: '#003545',
        },
    },
    typography: {
        fontFamily: [
            'ChakraPetch',
        ].join(','),
    },
});


export default function App() {
    const [mode, setMode] = React.useState('light');
    const colorMode = React.useMemo(
        () => ({
            toggleColorMode: () => {
                setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'));
            },
        }),
        [],
    );

    const theme = (mode === 'light' ? lightTheme : darkTheme);


    return (
        <ColorModeContext.Provider value={colorMode}>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <Routes>
                    <Route path="/" element={<Welcome />} />
                    <Route path="/s" element={<Root />}>
                        {navItems.map((item) => (
                            <Route key={item.id} path={item.route} element={item.element} />
                        ))}
                        {capabilities.map((item) => (
                            <Route key={item.id} path={item.route} element={item.element} />
                        ))}
                        {showcases.map((item) => (
                            <Route key={item.id} path={item.route} element={item.element} />
                        ))}
                        {news.map((item) => (
                            <Route key={item.id} path={item.route} element={item.element} />
                        ))}
                    </Route>
                    <Route path="*" element={<ErrorPage />} />
                </Routes>
            </ThemeProvider>
        </ColorModeContext.Provider>
    )
}