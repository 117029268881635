import {
    Grid,
    Container,
} from "@mui/material";
import * as React from 'react';
import squares from "../../assets/capability/squares.jpg";
import Hero from "../../components/hero";
import CapabilityCard from "../../components/capability_card";
import { capabilities } from '../../constants';
import { animated, useTrail } from '@react-spring/web'


export default function Capability() {
    React.useEffect(() => {
        document.title = "Capability";
        window.scrollTo(0, 0);
        const metaDescriptionElement = document.querySelector('meta[name="description"]') as HTMLMetaElement | null;
        if (metaDescriptionElement) {
            metaDescriptionElement.content = `Unlock Your Potential with Our Comprehensive Capabilities. 
            Discover how our expertise and innovative solutions can drive your business forward`;
        }
    }, []);

    const trailSprings = useTrail(capabilities.length, {
        from: {
            transform: "translateY(-200px)",
            opacity: 0,
        },
        to: {
            transform: "translateY(0px)",
            opacity: 1,
        }
    });

    return (
        <>

            <Container sx={{ my: 3 }}>
                <Hero pic={squares} title="Harnessing Our Limitless Capabilities" />
                <Grid
                    container
                    direction="row"
                    justifyContent="space-evenly"
                    alignItems="center"
                >

                    {capabilities.map((item, index) => (
                        <animated.div key={item.id} style={trailSprings[index]}>
                            <Grid item sx={{ m: 2 }}>
                                <CapabilityCard title={item.name} description={item.description} pic={item.pic} route={item.route} />
                            </Grid>
                        </animated.div>
                    ))}

                </Grid >
            </Container >
        </>
    );
}