import {
  Outlet,
} from "react-router-dom";

import DrawerAppBar from "../components/drawerappbar_outlet";
import Sidebar from "../components/sidebar";

export default function Root() {
  return (
    <>
      <DrawerAppBar main={<Outlet />} sidebar={<Sidebar />} />
    </>
  );

}