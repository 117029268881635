import Carousel from 'react-bootstrap/Carousel';

interface CarouselItemImgProps {
    pic: string;
    title: string;
}

const CarouselItemImg = (props: CarouselItemImgProps) => {
    return (
        <>
            <div style={{ position: 'relative' }}>
                <img
                    className="d-block w-100"
                    src={props.pic}
                    alt="First slide"
                    style={{
                        borderRadius: '10px',
                        height: '50vh',
                        width: '100%',
                        objectFit: 'cover',
                    }}
                />
                <div
                    style={{
                        position: 'absolute',
                        borderRadius: '10px',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0,0,0,.5)', // Background color with opacity
                    }}
                ></div>
            </div>
            <Carousel.Caption>
                <div style={{ color: 'white' }}>
                    <h4>{props.title}</h4>
                </div>
            </Carousel.Caption>
        </>
    );
};

export default CarouselItemImg;