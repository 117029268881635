import {
    Card,
    CardMedia,
    CardActions,
    Typography,
    CardContent,
    Button,
    Divider,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

interface ShowcaseCardProps {
    pic: string;
    route: string;
    title: string;
    description: string;
}

const ShowcaseCard = (props: ShowcaseCardProps) => {
    const navigate = useNavigate();
    const handleCardClick = () => {
        navigate(props.route);
    };

    return (
        <Card
            sx={{
                width: 250,
            }}
        >

            <CardMedia
                sx={{
                    height: 150, // Set the height as a percentage of the card's height
                    backgroundSize: "cover",
                    borderRadius: 3,
                }}
                image={props.pic}
                title={props.title}
            />
            <CardContent sx={{}}>
                <Typography gutterBottom sx={{
                    my: 1,
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    WebkitLineClamp: 1,
                }}>
                    {props.title}
                </Typography>
                <Divider />
                <Typography sx={{
                    my: 1,
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    WebkitLineClamp: 5,
                }} variant="body1" color="text.secondary">
                    {props.description}
                </Typography>
            </CardContent>
            <CardActions>
                <Button onClick={handleCardClick}>Learn More</Button>
            </CardActions>
        </Card >
    );
};

export default ShowcaseCard;