import BuildIcon from '@mui/icons-material/Build';
import HomeIcon from '@mui/icons-material/Home';
import MenuBookIcon from '@mui/icons-material/MenuBook';
import InfoIcon from '@mui/icons-material/Info';
// import ContactPageIcon from '@mui/icons-material/ContactPage';

import Home from './routes/home';
import About from './routes/about';
// import Contact from "./routes/contact";
import Voco from './routes/voco';
import Capability from './routes/capability/capability';
import Cybersecurity from './routes/capability/cybersecurity';
import DataEngineering from './routes/capability/data_engineering';
import DataScience from './routes/capability/data_science';
import DevOps from './routes/capability/devops';
import DigitalTransformation from './routes/capability/digital_tranformation';
import SoftwareEngineering from './routes/capability/software_engineering';
import Showcase from './routes/showcase/showcase';
import vul from "./assets/capability/vulnerability_management.png";
import mllc from "./assets/capability/machine_learning_lifecycle.png";
import cicd from "./assets/capability/devops/cicd.png";
import sdlc from "./assets/capability/software_development_lifecycle.png";
import chart from "./assets/chart.jpg";
import cms from "./assets/showcase/cms/contract_management_system.png";
import rs from './assets/showcase/rs/recommending_system.png';
import ultechUI from './assets/showcase/ultech_ui.png'
import voco from './assets/voco.png';
import ULTechCloud from './routes/showcase/ultech_cloud';
import RecommendingSystem from './routes/showcase/recommending_system';
import ContractManagementSystem from './routes/showcase/contract_management_system';
import devices from './assets/capability/devices.jpg';
import ECommerce from './routes/showcase/ecommerce';
import mpdProducts from './assets/showcase/mpd/mpd_products.png';
import SwamCert from './routes/news/swam_cert';

export const navItems = [
    {
        id: 'home',
        name: 'Home',
        route: '/s/home',
        icon: <HomeIcon />,
        element: <Home />,
    },
    {
        id: 'capability',
        name: 'Capability',
        route: '/s/capability',
        icon: <BuildIcon />,
        element: <Capability />,
    },
    {
        id: 'showcase',
        name: 'Showcase',
        route: '/s/showcase',
        icon: <MenuBookIcon />,
        element: <Showcase />,
    },
    {
        id: 'about',
        name: 'About',
        route: '/s/about',
        icon: <InfoIcon />,
        element: <About />,
    },
    {
        id: 'voco',
        name: 'Voco',
        route: '/s/voco',
        pic: voco,
        element: <Voco />,
    },
    /*
    {
        id: 'contact',
        name: 'Contact',
        route: '/s/contact',
        icon: <ContactPageIcon />,
        element: <Contact />,
    },
    */
];

export const capabilities = [
    {
        id: 'cybersecurity',
        name: 'Cybersecurity',
        route: '/s/capability/cybersecurity',
        pic: vul,
        element: <Cybersecurity />,
        description: ['Intrusion Detection', 'Vulnerability Management', 'Security Inident Investigation'],
    },
    {
        id: 'dataengineering',
        name: 'Data Engineering',
        route: '/s/capability/data-engineering',
        pic: chart,
        element: <DataEngineering />,
        description: ['Data ETL', 'Database Management', 'Data Processing'],
    },
    {
        id: 'datascience',
        name: 'Data Science',
        route: '/s/capability/data-science',
        pic: mllc,
        element: <DataScience />,
        description: ['Artificial Intelligence', 'Data Modeling', 'Machine Learning'],
    },
    {
        id: 'devops',
        name: 'DevOps Engineering',
        route: '/s/capability/devops',
        pic: cicd,
        element: <DevOps />,
        description: ['CIDE Pipeline', 'Cloud Computing', 'Incident Management'],
    },
    {
        id: 'digitaltransformation',
        name: 'Digital Transformation',
        route: '/s/capability/digital-transformation',
        pic: devices,
        element: <DigitalTransformation />,
        description: ['High Operation Efficiency', 'Good Customer Experience', 'Data-Driven Results'],
    },
    {
        id: 'softwareengineering',
        name: 'Software Engineering',
        route: '/s/capability/software-engineering',
        pic: sdlc,
        element: <SoftwareEngineering />,
        description: ['Application Development', 'Application Maintenance', 'System Design'],
    },
];

export const showcases = [
    /*
    {
        id: 'voco',
        name: 'Voco',
        route: '/s/showcase/voco',
        pic: voco,
        element: <Voco />,
        description: 'Voco is a revolutionary mobile app that seamlessly blends audio streaming and social media into a single platform, redefining the way we connect and share our ideas.',
    },
    */
    {
        id: 'ecommerce',
        name: 'eCommerce Website',
        route: '/s/showcase/ecommerce-website',
        pic: mpdProducts,
        element: <ECommerce />,
        description: 'eCommerce website built using serverless Lambda functions with Stripe and Auth0, with the front end developed in React.js..',
    },
    {
        id: 'recommendingsystem',
        name: 'Recommending System',
        route: '/s/showcase/recommending-system',
        pic: rs,
        element: <RecommendingSystem />,
        description: 'This mobile app recommends research articles for researchers based on their peferences. The system can connect peers with similar interests together. The system also has capabilities to fetch news and events.',
    },
    {
        id: 'contractmanagementsystem',
        name: 'Contract Management System',
        route: '/s/showcase/contract-management-system',
        pic: cms,
        element: <ContractManagementSystem />,
        description: 'The eCSRM is a proposed web-based solution for optimizing contract management, supplier relationship management, and system administration tasks.',
    },
    {
        id: 'ultechcloud',
        name: 'ULTech Cloud Website',
        route: '/s/showcase/ultech-cloud',
        pic: ultechUI,
        element: <ULTechCloud />,
        description: 'The official website for ULTech, built using React with Typescript, deployed to a static hosting service with no running cost.',
    },
];

export const news = [
    {
        id: 'swamCert',
        name: 'ULTech LLC Receives Small, Women Owned, and Minority Owned Certifications from Virginia Department of Small Business and Supplier Diversity',
        route: '/s/news/swam',
        element: <SwamCert />,
    },
];