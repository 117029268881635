import {
    Typography,
    Box
} from "@mui/material";
import * as React from 'react';

type HeroProps = {
    pic: string;
    title: string;
}

const Hero: React.FC<HeroProps> = ({ pic, title }) => {

    return (
        <Box
            sx={{
                position: 'relative',
                height: '50vh',
                backgroundImage: `url(${pic})`,
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                borderRadius: '10px',
                overflow: 'hidden', // Added overflow hidden to prevent image overflow
            }}
        >
            <Box
                sx={{
                    position: 'absolute',
                    top: 0,
                    bottom: 0,
                    right: 0,
                    left: 0,
                    backgroundColor: 'rgba(0,0,0,.4)',
                    borderRadius: '10px',
                }}
            />
            <Box
                sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    textAlign: 'center', // Added textAlign center to center the title
                    color: 'common.white',
                    zIndex: 1, // Added zIndex to ensure the title is on top
                }}
            >
                <Typography variant="h4" gutterBottom>
                    {title}
                </Typography>
            </Box>
        </Box>
    );
}

export default Hero;
