import * as React from 'react';
import { Typography } from '@mui/material';

export default function NotFoundPage(): React.ReactElement {
  return (
    <div id="not-found-page">
      <Typography variant="h1">404</Typography>
      <Typography variant="h4">Page Not Found</Typography>
      <Typography variant="body1">
        Sorry, the page you are looking for does not exist.
      </Typography>
    </div>
  );
}