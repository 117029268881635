import * as React from 'react';
import {
    Box,
    Typography,
    ListItem,
    List,
    Grid,
    Container,
} from "@mui/material";
import mllc from "../../assets/capability/machine_learning_lifecycle.png";

const DataScience = () => {
    React.useEffect(() => {
        document.title = "Data Science";
        window.scrollTo(0, 0);
        const metaDescriptionElement = document.querySelector('meta[name="description"]') as HTMLMetaElement | null;
        if (metaDescriptionElement) {
            metaDescriptionElement.content = `At ULTech, our data science expertise empowers organizations to extract meaningful insights from complex datasets, utilizing advanced analytics, machine learning, and AI techniques to drive data-informed decision-making and uncover valuable opportunities for growth and optimization.`;
        }
    }, []);

    return (
        <>
            <Container sx={{ my: 3 }}>
                <Grid container
                    direction="row"
                    justifyContent="space-evenly"
                    alignItems="center"
                    spacing={3} sx={{
                        my: 3
                    }}>
                    <Grid item >
                        <Typography gutterBottom variant="h5" component="div">
                            Data Science
                        </Typography>
                        <Typography gutterBottom variant="body1" sx={{ mb: 1, mx: 2 }}>
                            Our company is dedicated to delivering successful machine learning projects by following a comprehensive machine learning lifecycle. This structured approach ensures the effective development, deployment, and maintenance of machine learning models. Our machine learning life cycle encompasses the following key stages:
                        </Typography>
                    </Grid>

                    <Grid item >
                        <Box component="img" src={mllc} sx={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                            objectFit: 'contain',
                            display: 'block',
                            margin: 'auto',
                        }} />
                    </Grid>

                    <Grid item >
                        <Typography gutterBottom variant="h5" component="div">
                            Machine Learning Life Cycle
                        </Typography>
                        <List sx={{
                            listStyleType: 'decimal',
                        }} component="nav">
                            <ListItem divider sx={{ display: 'list-item' }}>
                                Business Goal: Clearly define the business objective and desired outcome for the machine learning project.
                            </ListItem>
                            <ListItem divider sx={{ display: 'list-item' }}>
                                ML Problem Framing: Identify and frame the specific machine learning problem that aligns with the business goal.
                            </ListItem>
                            <ListItem divider sx={{ display: 'list-item' }}>
                                Data Processing: Collect, preprocess, and transform the relevant data to ensure its quality and suitability for model development.
                            </ListItem>
                            <ListItem divider sx={{ display: 'list-item' }}>
                                Model Development: Design and develop the machine learning model architecture and algorithms tailored to the problem at hand.
                            </ListItem>
                            <ListItem divider sx={{ display: 'list-item' }}>
                                Model Training and Testing: Train the model using the prepared data and evaluate its performance through rigorous testing.
                            </ListItem>
                            <ListItem divider sx={{ display: 'list-item' }}>
                                Model Deployment: Deploy the trained model into production environments, integrating it with the necessary infrastructure.
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                                Monitoring: Continuously monitor the deployed model's performance, capturing feedback and ensuring it operates as intended.
                            </ListItem>
                        </List>
                    </Grid>
                </Grid >
            </Container>
        </>
    );
}

export default DataScience;