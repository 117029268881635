import {
    Card,
    CardMedia,
    CardActions,
    Typography,
    CardContent,
    Divider,
    Button,
} from "@mui/material";
import { useNavigate } from "react-router-dom";

interface CapabilityCardProps {
    pic: string;
    route: string;
    title: string;
    description: string[];
}

const CapabilityCard = (props: CapabilityCardProps) => {
    const navigate = useNavigate();
    const handleCardClick = () => {
        navigate(props.route);
    };

    return (
        <Card
            sx={{
                width: 250,
            }}
        >

            <CardMedia
                sx={{
                    height: 150, // Set the height as a percentage of the card's height
                    backgroundSize: "cover",
                    borderRadius: 3,
                }}
                image={props.pic}
                title={props.title}
            />
            <CardContent sx={{}}>
                <Typography sx={{
                    my: 1,
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    WebkitLineClamp: 1,
                }}
                    variant="h6"
                    gutterBottom>
                    {props.title}
                </Typography>
                {props.description.map((item, index) => (
                    <div key={index} >
                        <Divider />
                        <Typography sx={{
                            my: 1,
                            display: "-webkit-box",
                            WebkitBoxOrient: "vertical",
                            overflow: "hidden",
                            textOverflow: "ellipsis",
                            WebkitLineClamp: 1,
                        }} variant="body1" color="text.secondary">
                            {item}
                        </Typography>
                    </div>
                ))}
            </CardContent>
            <CardActions>
                <Button onClick={handleCardClick}>Learn More</Button>
            </CardActions>
        </Card>
    );
};

export default CapabilityCard;