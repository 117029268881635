import * as React from 'react';
import {
    Box,
    Typography,
    Grid,
    Container,
} from "@mui/material";
import rs from "../../assets/showcase/rs/recommending_system.png";
import rsUI1 from "../../assets/showcase/rs/rs_ui_1.png";
import rsUI2 from "../../assets/showcase/rs/rs_ui_2.png";
import rsUI3 from "../../assets/showcase/rs/rs_ui_3.png";
import rsUI4 from "../../assets/showcase/rs/rs_ui_4.png";
import rsUI5 from "../../assets/showcase/rs/rs_ui_5.png";

const RecommendingSystem = () => {
    React.useEffect(() => {
        document.title = "Recommending System";
        window.scrollTo(0, 0);
        const metaDescriptionElement = document.querySelector('meta[name="description"]') as HTMLMetaElement | null;
        if (metaDescriptionElement) {
            metaDescriptionElement.content = `ULTech's recommendation systems are meticulously designed to leverage advanced algorithms and data-driven insights, enabling organizations to deliver personalized recommendations, enhance customer engagement, and drive revenue growth through tailored and relevant experiences.`;
        }
    }, []);

    return (
        <>

            <Container sx={{ my: 3 }}>
                <Grid container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={3} sx={{
                        my: 3
                    }}>

                    <Grid item >
                        <Typography gutterBottom variant="h5" sx={{ mb: 1 }}>
                            Technical Approach
                        </Typography>
                        <Typography gutterBottom variant="body1" sx={{ mb: 1, mx: 2 }}>
                            ULTech's system design approach for this project focuses on delivering a robust and efficient mobile app that meets the project requirements. The approach includes integration with the API for accessing events and news, connectivity through DNS and load balancers, read and write APIs for data retrieval and storage, a CDN for fast file delivery, a recommendation system for personalized suggestions, data redundancy for reliability, cross-platform development for cost-efficiency, and a skilled team composition. This approach ensures seamless integration, optimal performance, personalized user experience, data reliability, cross-platform compatibility, and high-quality deliverables.
                        </Typography>
                    </Grid>

                    <Grid item >
                        <Box component='img' src={rs} sx={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                            objectFit: 'contain',
                            display: 'block',
                            margin: 'auto',
                        }} >
                        </Box >
                    </Grid>

                    <Grid item >
                        <Typography gutterBottom variant="h6" sx={{ mb: 1, mt: 3 }}>
                            System Design
                        </Typography>
                        <Typography gutterBottom variant="body1" sx={{ mb: 1, mx: 2 }}>
                            To ensure the efficient and reliable functioning of the mobile app and support its various functionalities, ULTech will employ a robust system design approach:
                        </Typography>
                    </Grid>

                    <Grid item lg={6} md={6} >
                        <Typography gutterBottom variant="h6" sx={{ mb: 1, mx: 2 }}>
                            Mobile App Integration with API:
                        </Typography>
                        <Typography gutterBottom variant="body1" sx={{ mb: 1, mx: 4 }}>
                            The mobile app will utilize the API endpoint to fetch events and news.
                        </Typography>
                        <Typography gutterBottom variant="body1" sx={{ mb: 1, mx: 4 }}>
                            ULTech will integrate the app seamlessly with the API, allowing users to access up-to-date information directly from the database.
                        </Typography>

                        <Typography gutterBottom variant="h6" sx={{ mt: 3, mb: 1, mx: 2 }}>
                            System Connectivity via DNS and Load Balancers:
                        </Typography>
                        <Typography gutterBottom variant="body1" sx={{ mb: 1, mx: 4 }}>
                            To connect the mobile app with ULTech's system, DNS and load balancers will be employed.
                        </Typography>
                        <Typography gutterBottom variant="body1" sx={{ mb: 1, mx: 4 }}>
                            Load balancers will distribute incoming requests from the app to ULTech's read and write APIs, ensuring optimal performance and scalability.
                        </Typography>
                    </Grid>

                    <Grid item lg={6} md={6}>
                        <Box component='img' src={rsUI1} sx={{
                            maxWidth: '50%',
                            maxHeight: '50%',
                            objectFit: 'contain',
                            display: 'block',
                            margin: 'auto',
                        }} >
                        </Box >
                    </Grid>

                    <Grid item lg={6} md={6}>
                        <Box component='img' src={rsUI2} sx={{
                            maxWidth: '50%',
                            maxHeight: '50%',
                            objectFit: 'contain',
                            display: 'block',
                            margin: 'auto',
                        }} >
                        </Box >
                    </Grid>

                    <Grid item lg={6} md={6}>
                        <Typography gutterBottom variant="h6" sx={{ mb: 1, mx: 2 }}>
                            Read API:
                        </Typography>
                        <Typography gutterBottom variant="body1" sx={{ mb: 1, mx: 4 }}>
                            The Read API will serve as a gateway for retrieving data from various sources. It will read information from the SQL replica, including data related to favorite papers, events, news, connections, and more.
                        </Typography>
                        <Typography gutterBottom variant="body1" sx={{ mb: 1, mx: 4 }}>
                            Additionally, the Read API will access the object store to retrieve files such as pictures, videos, and PDFs. For improved performance, it will also utilize a memory cache.
                        </Typography>

                        <Typography gutterBottom variant="h6" sx={{ mt: 3, mb: 1, mx: 2 }}>
                            Write API:
                        </Typography>
                        <Typography gutterBottom variant="body1" sx={{ mb: 1, mx: 4 }}>
                            The Write API will handle data storage operations. It will write user-related information, such as favorite papers, events, news, and connections, to the SQL primary database.
                        </Typography>
                        <Typography gutterBottom variant="body1" sx={{ mb: 1, mx: 4 }}>
                            Files, such as pictures, videos, and PDFs will be stored in the object store.
                        </Typography>
                    </Grid>


                    <Grid item lg={6} md={6}>
                        <Typography gutterBottom variant="h6" sx={{ mb: 1, mx: 2 }}>
                            Content Delivery Network (CDN):
                        </Typography>
                        <Typography gutterBottom variant="body1" sx={{ mb: 1, mx: 4 }}>
                            A CDN will be implemented to serve files directly from the object store to the mobile app. This approach ensures fast retrieval of multimedia content and enhances the overall user experience.
                        </Typography>
                        <Typography gutterBottom variant="h6" sx={{ mt: 3, mb: 1, mx: 2 }}>
                            Recommendation System:
                        </Typography>
                        <Typography gutterBottom variant="body1" sx={{ mb: 1, mx: 4 }}>
                            ULTech will develop a recommendation system that leverages data from the Read API, including user preferences.
                        </Typography>
                        <Typography gutterBottom variant="body1" sx={{ mb: 1, mx: 4 }}>
                            The system will analyze this data and generate personalized recommendations, which will be pushed to the mobile app. This feature will enhance user engagement and facilitate the discovery of relevant content.
                        </Typography>
                    </Grid>

                    <Grid item lg={6} md={6} >
                        <Box component='img' src={rsUI3} sx={{
                            maxWidth: '50%',
                            maxHeight: '50%',
                            objectFit: 'contain',
                            display: 'block',
                            margin: 'auto',
                        }} >
                        </Box >
                    </Grid>

                    <Grid item lg={6} md={6}>
                        <Box component='img' src={rsUI4} sx={{
                            maxWidth: '50%',
                            maxHeight: '50%',
                            objectFit: 'contain',
                            display: 'block',
                            margin: 'auto',
                        }} >
                        </Box >
                    </Grid>

                    <Grid item lg={6} md={6}>
                        <Typography gutterBottom variant="h6" sx={{ mb: 1, mx: 2 }}>
                            Data Redundancy and Performance Optimization:
                        </Typography>
                        <Typography gutterBottom variant="body1" sx={{ mb: 1, mx: 4 }}>
                            To ensure data integrity and improve system performance, ULTech will duplicate the memory cache, database, and file storage. This redundancy approach will provide fault tolerance and minimize downtime.
                        </Typography>
                        <Typography gutterBottom variant="body1" sx={{ mb: 1, mx: 4 }}>
                            Additionally, ULTech will deploy multiple computing services to handle high traffic loads efficiently
                        </Typography>
                        <Typography gutterBottom variant="h6" sx={{ mt: 3, mb: 1, mx: 2 }}>
                            Cross-Platform Development:
                        </Typography>
                        <Typography gutterBottom variant="body1" sx={{ mb: 1, mx: 4 }}>
                            ULTech utilizes a single development framework that supports both iOS and Android platforms. This approach allows ULTech to develop mobile applications in a cost-effective manner, reducing the need for platform-specific development. This unified approach saves time and resources while maintaining high-quality standards.
                        </Typography>
                    </Grid>

                    <Grid item lg={6} md={6}>
                        <Typography gutterBottom variant="body1" sx={{ mb: 1, mx: 4 }}>
                            By implementing this comprehensive system design approach, ULTech will deliver a mobile app that seamlessly integrates data, supports various functionalities, ensures data redundancy, and provides a smooth user experience across multiple platforms.
                        </Typography>
                    </Grid>

                    <Grid item lg={6} md={6} >
                        <Box component='img' src={rsUI5} sx={{
                            maxWidth: '50%',
                            maxHeight: '50%',
                            objectFit: 'contain',
                            display: 'block',
                            margin: 'auto',
                        }} >
                        </Box >
                    </Grid>





                </Grid >
            </Container>

        </>
    );
}

export default RecommendingSystem;