import {
    Grid,
    Container,
} from "@mui/material";
import * as React from 'react';
import showcase from "../../assets/showcase/showcase.jpg";
import Hero from "../../components/hero";
import ShowcaseCard from "../../components/showcase_card";
import { showcases } from '../../constants';
import { animated, useTrail } from '@react-spring/web'


export default function Showcase() {
    React.useEffect(() => {
        document.title = "Showcase";
        window.scrollTo(0, 0);
        const metaDescriptionElement = document.querySelector('meta[name="description"]') as HTMLMetaElement | null;
        if (metaDescriptionElement) {
            metaDescriptionElement.content = `Experience Our Exceptional Showcase. Explore a collection of our finest work, 
            highlighting our expertise, creativity, and successful client collaborations`;
        }
    }, []);

    const trailSprings = useTrail(showcases.length, {
        from: {
            transform: "translateY(-200px)",
            opacity: 0,
        },
        to: {
            transform: "translateY(0px)",
            opacity: 1,
        }
    });

    return (
        <>

            <Container sx={{ my: 3 }}>
                <Hero pic={showcase} title="Celebrating Our Finest Achievements" />
                <Grid
                    container
                    direction="row"
                    justifyContent="space-evenly"
                    alignItems="center"
                >
                    {showcases.map((item, index) => (
                        <animated.div key={item.id} style={trailSprings[index]}>
                            <Grid item sx={{ m: 2 }}>
                                <ShowcaseCard title={item.name} description={item.description} pic={item.pic} route={item.route} />
                            </Grid>
                        </animated.div>
                    ))}
                </Grid>
            </Container>
        </>
    );
}