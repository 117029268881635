import * as React from 'react';
import {
    Box,
    Typography,
    ListItem,
    List,
    Grid,
    Container,
} from "@mui/material";
import cicd from "../../assets/capability/devops/cicd.png";
import ir from "../../assets/capability/devops/incident_response.png";

const DevOps = () => {
    React.useEffect(() => {
        document.title = "DevOps";
        window.scrollTo(0, 0);
        const metaDescriptionElement = document.querySelector('meta[name="description"]') as HTMLMetaElement | null;
        if (metaDescriptionElement) {
            metaDescriptionElement.content = `At ULTech, our DevOps practices combine development and operations methodologies to streamline software delivery, enhance collaboration, and accelerate innovation, enabling organizations to achieve continuous integration, deployment, and delivery with efficiency and reliability.`;
        }
    }, []);

    return (
        <>

            <Container sx={{ my: 3 }}>
                <Grid container
                    spacing={3} sx={{
                        my: 3
                    }}>

                    <Grid item lg={6} md={6}>
                        <Box component='img' src={cicd} sx={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                            objectFit: 'contain',
                            display: 'block',
                            margin: 'auto',
                        }} >
                        </Box >
                    </Grid>
                    <Grid item lg={6} md={6}>
                        <Typography gutterBottom variant="h5" component="div">
                            CICD
                        </Typography>
                        <Typography gutterBottom variant="body1" sx={{ mb: 1, mx: 2 }}>
                            ULTech follow a streamlined CI/CD (Continuous Integration/Continuous Delivery) lifecycle that consists of the following steps:
                        </Typography>
                        <List sx={{
                            listStyleType: 'decimal',
                        }} component="nav">
                            <ListItem divider sx={{ display: 'list-item' }}>
                                Code: Skilled developers write and commit code changes to a version control system, ensuring code quality and adherence to coding standards.
                            </ListItem>
                            <ListItem divider sx={{ display: 'list-item' }}>
                                Build: Automated processes are initiated to compile the code and create a software build, allowing for efficient and consistent software generation.
                            </ListItem>
                            <ListItem divider sx={{ display: 'list-item' }}>
                                Test: The built software undergoes rigorous testing, including unit testing, integration testing, and other relevant tests, to identify and resolve any issues or bugs.
                            </ListItem>
                            <ListItem divider sx={{ display: 'list-item' }}>
                                Release: The tested software is prepared for release, ensuring it meets the necessary quality criteria and is ready for deployment.
                            </ListItem>
                            <ListItem divider sx={{ display: 'list-item' }}>
                                Deploy: The software is deployed to the desired environment, following defined deployment strategies, to make it available for end-users.
                            </ListItem>
                            <ListItem divider sx={{ display: 'list-item' }}>
                                Create: Additional infrastructure or resources, such as databases or virtual machines, are created or configured as required to support the deployed software.
                            </ListItem>
                            <ListItem divider sx={{ display: 'list-item' }}>
                                Monitor: Ongoing monitoring and observability mechanisms are implemented to track the performance, availability, and security of the deployed software.
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                                Plan: Based on monitoring and feedback, future development plans and improvements are identified, prioritized, and planned for future iterations.
                            </ListItem>
                        </List>
                    </Grid>
                    <Grid item>
                        <Typography gutterBottom variant="body1" component="div">
                            Our company excels in delivering efficient and reliable DevOps Engineering solutions. We prioritize incident management, automation, and ensuring compliance and security, allowing our clients to focus on their core business while we optimize their systems for high performance and availability.
                        </Typography>
                    </Grid>
                    <Grid item lg={2} md={4} sm={6}>
                        <Box component='img' src={ir} sx={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                            objectFit: 'contain',
                            display: 'block',
                            margin: 'auto',
                        }} >
                        </Box >
                    </Grid>
                    <Grid item lg={10} md={8} sm={6}>
                        <Typography gutterBottom variant="h5" component="div">
                            Incident Response
                        </Typography>
                        <Typography gutterBottom variant="body1" sx={{ mb: 1, mx: 2 }}>
                            Our company has a well-defined incident response process that follows a structured approach to effectively address and manage incidents. Our process aligns with industry best practices.
                        </Typography>
                        <List sx={{
                            listStyleType: 'decimal',
                        }} component="nav">
                            <ListItem divider sx={{ display: 'list-item' }}>
                                Identification: Our proactive monitoring systems and vigilant teams detect and identify incidents promptly, ensuring swift action.
                            </ListItem>
                            <ListItem divider sx={{ display: 'list-item' }}>
                                Logging: We meticulously record all relevant details of the incident, including timestamps, incident nature, and initial observations. This comprehensive logging enables accurate tracking and analysis.
                            </ListItem>
                            <ListItem divider sx={{ display: 'list-item' }}>
                                Prioritization: We assess the impact and urgency of each incident to determine its priority level. This allows us to allocate appropriate resources and respond accordingly based on the severity and potential consequences. If necessary, we escalate incidents to specialized teams or higher management levels, ensuring that the incident receives the required attention and expertise for timely resolution.
                            </ListItem>
                            <ListItem divider sx={{ display: 'list-item' }}>
                                Investigation and Diagnosis: Our experienced incident response team conducts thorough investigations to identify the root cause, scope, and potential impact of the incident. We employ advanced tools and techniques to gather and analyze relevant information, system logs, and perform forensic analysis when necessary.
                            </ListItem>
                            <ListItem divider sx={{ display: 'list-item' }}>
                                Response and Containment: We swiftly respond to incidents, taking immediate actions to contain their impact and prevent further harm. Our team applies industry-leading practices to isolate affected systems, shut down compromised accounts, or implement temporary fixes.
                            </ListItem>
                            <ListItem divider sx={{ display: 'list-item' }}>
                                Remediation: We develop comprehensive plans to address the root cause of the incident, implementing long-term solutions to prevent similar incidents from recurring. This may involve fixing vulnerabilities, applying patches, or enhancing security measures.
                            </ListItem>
                            <ListItem divider sx={{ display: 'list-item' }}>
                                Recovery: Our dedicated team focuses on restoring affected systems or services to their normal functioning state. We prioritize data restoration, system reconfiguration, and leverage backup solutions to minimize downtime and restore business operations.
                            </ListItem>
                            <ListItem divider sx={{ display: 'list-item' }}>
                                Documentation: We maintain meticulous records of all incident details, actions taken, and lessons learned. This documentation supports post-incident analysis, reporting, and continuous improvement of our incident response capabilities.
                            </ListItem>
                            <ListItem divider sx={{ display: 'list-item' }}>
                                Communication: We understand the importance of clear and effective communication during incident response. Our team keeps stakeholders informed about the incident's impact, progress, and resolution, ensuring transparency, and managing expectations.
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                                Post-Incident Review: We conduct thorough post-incident reviews to evaluate the effectiveness of our incident response process. We identify areas for improvement, implement necessary changes, and continuously enhance our incident response capabilities.
                            </ListItem>
                        </List>
                    </Grid>
                </Grid >
            </Container>

        </>
    );
}

export default DevOps;