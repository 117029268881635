import * as React from 'react';
import {
    Typography,
    Grid,
    Container,
} from "@mui/material";

const SwamCert = () => {
    React.useEffect(() => {
        document.title = "VA SWaM Certified";
        window.scrollTo(0, 0);
        const metaDescriptionElement = document.querySelector('meta[name="description"]') as HTMLMetaElement | null;
        if (metaDescriptionElement) {
            metaDescriptionElement.content = `ULTech LLC Receives Small, Women Owned, and Minority Owned Certifications from Virginia Department of Small Business and Supplier Diversity`;
        }
    }, []);

    return (
        <>

            <Container sx={{ my: 3 }}>
                <Grid container
                    direction="row"
                    justifyContent="space-evenly"
                    alignItems="center"
                    spacing={3} sx={{
                        my: 3
                    }}>
                    <Grid item >
                        <Typography gutterBottom variant="h5" sx={{ mb: 1 }}>
                            ULTech LLC Receives Small, Women Owned, and Minority Owned Certifications from Virginia Department of Small Business and Supplier Diversity
                        </Typography>
                        <Typography gutterBottom variant="body1" sx={{ mb: 1, mx: 2 }}>
                            ULTech LLC, a prominent technology company, has recently been granted Small Business, Women Owned, and Minority Owned certifications by the Virginia Department of Small Business and Supplier Diversity (SBSD). The certifications mark a significant milestone in ULTech's commitment to diversity and inclusion.
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography gutterBottom variant="body1" sx={{ mb: 1, mx: 2 }}>
                            The Small Business, Women Owned, and Minority Owned certifications will empower ULTech LLC to participate in a broader range of business opportunities and collaborations, particularly within the Commonwealth of Virginia. These designations demonstrate ULTech's dedication to supporting diversity in the business community and fostering equal opportunities for all.
                        </Typography>


                        <Typography gutterBottom variant="body1" sx={{ mb: 1, mx: 2 }}>
                            ULTech LLC expresses its gratitude to the Virginia Department of Small Business and Supplier Diversity for granting these certifications and looks forward to leveraging them to strengthen its presence in the Virginia business community.
                            For inquiries or additional information, please contact ULTech LLC at <a href="mailto:contact@ultech.cloud">contact@ultech.cloud</a>.
                        </Typography>



                    </Grid>

                    <Grid item>
                        <Typography gutterBottom variant="h6" sx={{ mb: 1, mt: 3 }}>
                            About ULTech LLC:
                        </Typography>

                        <Typography gutterBottom variant="body1" sx={{ mb: 1, mx: 2 }}>
                            ULTech LLC is a leading technology company specializing in [describe company's core services or products]. With a commitment to innovation and diversity, ULTech LLC strives to deliver cutting-edge solutions while fostering an inclusive business environment.
                        </Typography>
                    </Grid>


                </Grid >
            </Container >

        </>
    );
}

export default SwamCert;