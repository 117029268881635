import {
  Grid,
  Typography,
  Container,
} from "@mui/material";
import * as React from 'react';

export default function Voco() {
  React.useEffect(() => {
    document.title = "Voco";
    window.scrollTo(0, 0);
    const metaDescriptionElement = document.querySelector('meta[name="description"]') as HTMLMetaElement | null;
    if (metaDescriptionElement) {
      metaDescriptionElement.content = `Authentic voice interactions, live streams, and chat rooms in one app.`;
    }
  }, []);


  return (
    <>
      <Container sx={{ my: 3 }}>
        <Grid container
          direction="row"
          justifyContent="space-evenly"
          alignItems="center"
          spacing={3} sx={{
            my: 3
          }}>
          <Grid item >
            <Typography variant="h5" sx={{ mb: 1 }}>
              About Voco
            </Typography>
            <Typography variant="body1" sx={{ mb: 1, mx: 2 }}>
              Go to {' '}
              <a
                href="https://www.voco.live/"
                target="_blank"
                rel="noopener noreferrer"
                style={{ textDecoration: "underline", color: "#007BFF" }}
              >
                Voco
              </a>
            </Typography>
            <Typography variant="body1" sx={{ mb: 1, mx: 2 }}>
              Voco blends audio streaming and social media into a single platform, redefining the way we connect and share our ideas.
              With its inspiring motto, "let it get heard," Voco is designed to empower individuals with unique talents and innovative thoughts, offering them an inclusive platform to showcase their creativity.
              Whether you're a budding musician, a motivational speaker, or simply someone with a compelling story to tell, Voco provides the perfect stage to amplify your voice.
              What sets Voco apart is its commitment to inclusivity, making it accessible to everyone by offering a completely free user experience.
              So, join the Voco community today, and let your passion, ideas, and skills be heard by the world.
            </Typography>
          </Grid>
        </Grid >
      </Container >
    </>
  );
}