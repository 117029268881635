import * as React from 'react';
import {
    Box,
    Typography,
    ListItem,
    List,
    Grid,
    Container,
} from "@mui/material";
import sdlc from "../../assets/capability/software_development_lifecycle.png";

const SoftwareEngineering = () => {
    React.useEffect(() => {
        document.title = "Software Engineering";
        window.scrollTo(0, 0);
        const metaDescriptionElement = document.querySelector('meta[name="description"]') as HTMLMetaElement | null;
        if (metaDescriptionElement) {
            metaDescriptionElement.content = `At ULTech, our software engineering expertise enables us to develop robust and innovative software solutions tailored to meet the unique needs of our clients, delivering high-quality and scalable applications.`;
        }
    }, []);

    return (
        <>

            <Container sx={{ my: 3 }}>
                <Grid container spacing={2}>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Box component='img' src={sdlc} sx={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                            objectFit: 'contain',
                            display: 'block',
                            margin: 'auto',
                        }} >
                        </Box >
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <List sx={{
                            listStyleType: 'decimal',
                        }} component="nav">
                            <Typography gutterBottom variant="h5" component="div">
                                SDLC - We follow a streamlined Software Development Lifecycle (SDLC) consisting of the following stages:
                            </Typography>
                            <ListItem divider sx={{ display: 'list-item' }}>
                                Discovery: We engage in thorough discussions with our clients to understand their requirements, objectives, and constraints. This phase involves gathering information, conducting research, and identifying the project scope.
                            </ListItem>
                            <ListItem divider sx={{ display: 'list-item' }}>
                                Design: Based on the discovered requirements, our team of designers and architects create a detailed plan and system architecture for the software solution. This stage includes user interface design, database design, and overall system structure.
                            </ListItem>
                            <ListItem divider sx={{ display: 'list-item' }}>
                                Development: Our skilled developers bring the design to life by coding and programming the software solution. They follow best practices, coding standards, and utilize appropriate technologies to ensure efficient and maintainable code.
                            </ListItem>
                            <ListItem divider sx={{ display: 'list-item' }}>
                                Testing & QA: We conduct comprehensive testing at various levels to ensure the quality and reliability of the software. This includes unit testing, integration testing, system testing, and user acceptance testing. Our dedicated Quality Assurance (QA) team performs thorough checks to identify and resolve any issues or bugs.
                            </ListItem>
                            <ListItem divider sx={{ display: 'list-item' }}>
                                Release: After successful testing and quality assurance, we prepare the software for release. We follow a controlled release process, ensuring that the software is deployed smoothly and securely into the production environment.
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                                Maintenance: Once the software is deployed, we provide ongoing maintenance and support services. This includes monitoring the software's performance, addressing user feedback, and applying updates or patches as needed.
                            </ListItem>
                        </List>
                    </Grid>
                </Grid >
            </Container>

        </>
    );
}

export default SoftwareEngineering;