import * as React from 'react';
import {
    Typography,
    Grid,
    Container,
} from "@mui/material";

const DataEngineering = () => {
    React.useEffect(() => {
        document.title = "Data Engineering";
        window.scrollTo(0, 0);
        const metaDescriptionElement = document.querySelector('meta[name="description"]') as HTMLMetaElement | null;
        if (metaDescriptionElement) {
            metaDescriptionElement.content = `ULTech excels in data engineering, harnessing robust data architectures, efficient data pipelines, and advanced processing techniques to ensure the smooth collection, integration, and transformation of data, empowering organizations to derive valuable insights and make data-driven decisions.`;
        }
    }, []);

    return (
        <>
            <Container sx={{ my: 3 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h6" gutterBottom>
                            What does data engineering do?
                        </Typography>
                        <Typography variant="body1">
                            Data engineering plays a crucial role in the data-driven landscape, enabling organizations to collect, process, and transform data for various purposes.
                        </Typography>
                        <Typography variant="body1">
                            Here are some key things that data engineering can do:
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1">
                            1. Data Collection and Integration:
                        </Typography>
                        <Typography variant="body1">
                            Data engineering involves designing and implementing efficient data collection processes and integrating data from various sources. It ensures that data is collected accurately, efficiently, and in a format suitable for analysis and decision-making.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1">
                            2. Data Processing and Transformation:
                        </Typography>
                        <Typography variant="body1">
                            Data engineering focuses on processing and transforming raw data into a clean, structured, and usable format. It involves tasks such as data cleaning, data validation, data enrichment, and data aggregation to provide reliable and high-quality data for analysis.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1">
                            3. Data Pipeline and ETL Development:
                        </Typography>
                        <Typography variant="body1">
                            Data engineering is responsible for building data pipelines and ETL (Extract, Transform, Load) processes. It ensures the smooth flow of data from source systems to data storage or analytical platforms, enabling efficient data movement, transformation, and integration.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1">
                            4. Big Data and Cloud Technologies:
                        </Typography>
                        <Typography variant="body1">
                            Data engineering leverages big data and cloud technologies to handle large volumes of data and achieve scalability. It involves working with technologies like Hadoop, Spark, Kafka, and cloud-based platforms to store, process, and analyze data at scale.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1">
                            5. ULTech's Expertise in Data Engineering:
                        </Typography>
                        <Typography variant="body1">
                            ULTech excels in data engineering, leveraging its expertise to design robust data architectures, develop efficient data pipelines, and implement advanced data processing and transformation techniques. With a focus on data engineering excellence, ULTech helps organizations unlock the full potential of their data assets.
                        </Typography>
                    </Grid>
                    {/* Add more Grid items for additional paragraphs if needed */}
                </Grid>
            </Container>

        </>
    );
}

export default DataEngineering;