import {
    Box,
    Typography,
    Button,
} from "@mui/material";
import dna from "../assets/dna.mp4";
import { useNavigate } from 'react-router-dom';
import * as React from 'react';
import ultechlogo from "../assets/ULTech_logos_white.png";
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import dnaFrame from '../assets/dna_frame.jpg';
import { motion } from "framer-motion";

export default function Welcome() {
    React.useEffect(() => {
        document.title = "ULTech LLC";
        window.scrollTo(0, 0);
        const metaDescriptionElement = document.querySelector('meta[name="description"]') as HTMLMetaElement | null;
        if (metaDescriptionElement) {
            metaDescriptionElement.content = 'ULTech LLC - Your Trusted IT Consulting Partner. At ULTech LLC, we are committed to being your reliable IT consulting partner, providing comprehensive solutions to meet your business needs in the digital age.';
        }
    }, []);

    const navigate = useNavigate();

    const container = {
        hidden: { opacity: 0 },
        show: {
            opacity: 1,
            transition: {
                staggerChildren: 0.5,

            }
        }
    }

    const item = {
        hidden: { opacity: 0 },
        show: {
            opacity: 1,
            transition: {
                duration: 0.5,
            }
        }
    }

    return (
        <>
            <Box
                sx={{
                    position: 'fixed',
                    top: 0,
                    bottom: 0,
                    right: 0,
                    left: 0,
                    width: '100vw',
                    height: '100vh',
                    zIndex: -1,
                    overflow: 'hidden',
                }}
            >
                <video
                    autoPlay
                    muted
                    loop
                    playsInline
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        objectFit: 'cover',
                    }}
                    poster={dnaFrame}
                >
                    <source src={dna} type="video/mp4" />
                </video>
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        bottom: 0,
                        right: 0,
                        left: 0,
                        backgroundColor: 'rgba(0,0,0,.3)',
                    }}
                />
            </Box >
            <Box
                sx={{
                    color: 'common.white',
                    textAlign: 'center',
                    height: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: '1rem',
                }}
            >
                <motion.div variants={container}
                    initial="hidden"
                    animate="show">
                    <motion.div variants={item}>
                        <img
                            src={ultechlogo}
                            alt="ULTech Logo"
                            style={{
                                maxHeight: '300px',
                                width: '100%',
                                objectFit: 'contain',
                            }}
                        />
                    </motion.div>
                    <motion.div variants={item}>
                        <Typography variant="h4" gutterBottom>
                            UnLeashed Technology
                        </Typography>
                    </motion.div>
                    <motion.div variants={item}>
                        <Typography variant="h6" gutterBottom>
                            Ignite your IT potential with reimagination.
                        </Typography>
                    </motion.div>

                    <motion.div
                        animate={{
                            scale: [1, 1.1, 1],
                        }}
                        transition={{
                            duration: 1,
                            ease: "easeInOut",
                            repeat: Infinity,
                            repeatDelay: 1
                        }}
                    >
                        <Button
                            variant="contained"
                            size="large"
                            onClick={() => navigate("/s/home")}
                            endIcon={<ArrowForwardIcon />}
                        >
                            Explore
                        </Button>
                    </motion.div>
                </motion.div>
            </Box >

        </>
    );
}
