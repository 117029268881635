import * as React from 'react';
import {
    Box,
    Typography,
    ListItem,
    List,
    Grid,
    Container,
} from "@mui/material";
import cms from "../../assets/showcase/cms/contract_management_system.png";
import cmsUI1 from "../../assets/showcase/cms/cms_ui_1.png";
import cmsUI2 from "../../assets/showcase/cms/cms_ui_2.png";
import cmsUI3 from "../../assets/showcase/cms/cms_ui_3.png";

const ContractManagementSystem = () => {
    React.useEffect(() => {
        document.title = "Contract Management System";
        window.scrollTo(0, 0);
        const metaDescriptionElement = document.querySelector('meta[name="description"]') as HTMLMetaElement | null;
        if (metaDescriptionElement) {
            metaDescriptionElement.content = `ULTech's contract management system offers a comprehensive solution that streamlines contract lifecycle management, ensuring efficient creation, collaboration, tracking, and compliance management, enabling organizations to optimize their contract processes and mitigate risks effectively.`;
        }
    }, []);

    return (
        <>

            <Container sx={{ my: 3 }}>
                <Grid container
                    direction="row"
                    justifyContent="space-evenly"
                    alignItems="center"
                    spacing={3} sx={{
                        my: 3
                    }}>
                    <Grid item >
                        <Typography gutterBottom variant="h5" sx={{ mb: 1 }}>
                            Technical Approach and Methodology
                        </Typography>
                        <Typography gutterBottom variant="body1" sx={{ mb: 1, mx: 2 }}>
                            The eCSRM is a proposed web-based solution for optimizing contract management, supplier
                            relationship management, and system administration tasks. It will have a
                            user-friendly interface and diverse functionalities. For
                            the proof of concept, we propose an initial system architecture design (Figure 1) considering
                            user and transaction volume.
                        </Typography>
                    </Grid>
                    <Grid item >
                        <Box component='img' src={cms} sx={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                            objectFit: 'contain',
                            display: 'block',
                            margin: 'auto',
                        }} >
                        </Box >
                        <Typography gutterBottom variant="body1" component="div">
                            Figure 1. System Architecture Design
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography gutterBottom variant="h6" sx={{ mb: 1, mt: 3 }}>
                            System Architecture and Scalability
                        </Typography>
                        <Typography gutterBottom variant="body1" sx={{ mb: 1, mx: 2 }}>

                            The proposed eCSRM system is designed with a scalable architecture to accommodate future
                            growth and increasing demands. The eCSRM system is a web-based solution, providing easy
                            access through standard web browsers without the need for client-side installations.
                        </Typography>
                        <List>
                            <ListItem>
                                ● Scalability Considerations: The system is designed for scalability, allowing horizontal or
                                vertical scaling to handle increasing user base and transaction volume.
                            </ListItem>
                            <ListItem>
                                ● Containerization: The application is containerized using Docker, offering isolation,
                                portability, and simplified deployment across different environments. It supports
                                horizontal scaling by spinning up additional containers as needed.
                            </ListItem>
                            <ListItem>
                                ● Modern Tools and Frameworks: Modern technologies like Python, Flask, Django, React,
                                or Angular are utilized to enhance development productivity and user experience.
                            </ListItem>
                            <ListItem>
                                ● Interfacing with Existing Systems: The system integrates with other systems, facilitating
                                data exchange and ensuring consistency across different platforms.
                            </ListItem>
                            <ListItem>
                                ● Continuous Integration and Deployment (CI/CD): CI/CD practices automate the build,
                                testing, and deployment processes, ensuring efficient development and reliable software
                                releases.
                            </ListItem>
                            <ListItem>
                                ● Monitoring and Maintenance: The system includes monitoring tools, alerts, and access
                                to maintenance information and log files for proactive issue detection and optimal system
                                performance.
                            </ListItem>
                        </List>


                        <Typography gutterBottom variant="h6" sx={{ mb: 1, mt: 3 }}>
                            Authentication and Access Control
                        </Typography>
                        <Typography gutterBottom variant="body1" sx={{ mb: 1, mx: 2 }}>
                            The proposed approach incorporates robust authentication mechanisms and fine-grained
                            access control to safeguard sensitive information and ensure that users have appropriate
                            privileges based on their roles and responsibilities.
                        </Typography>
                        <List>
                            <ListItem>
                                ● Identity Provider Integration: The system will integrate with established identity providers
                                such as LDAP (Lightweight Directory Access Protocol) or Azure AD (Active Directory) for
                                user authentication, enabling centralized user management and secure authentication.
                            </ListItem>
                            <ListItem>
                                ● Token-based Authentication: Users obtain a login token from the identity provider for
                                secure authentication without transmitting passwords with each request.
                            </ListItem>
                            <ListItem>
                                ● Role-based Access Control: The system will implement a role-based access control
                                (RBAC) mechanism to assign specific privileges to users based on their roles and
                                responsibilities, ensuring access control and data confidentiality.
                            </ListItem>
                            <ListItem>
                                ● User Profile Management: System administrators manage user profiles, granting
                                privileges and adjusting access as needed to align with organizational changes.
                            </ListItem>
                            <ListItem>
                                ● Access Restriction for External Users: Access control policies restrict external users'
                                visibility to protect sensitive data, ensuring only necessary information is accessible.
                            </ListItem>
                        </List>

                    </Grid>

                    <Grid item >
                        <Box component='img' src={cmsUI1} sx={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                            objectFit: 'contain',
                            display: 'block',
                            margin: 'auto',
                        }} >
                        </Box >
                        <Typography gutterBottom variant="body1" component="div">
                            Figure 2. Performance Dashboard
                        </Typography>
                    </Grid>
                    <Grid item >
                        <Box component='img' src={cmsUI2} sx={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                            objectFit: 'contain',
                            display: 'block',
                            margin: 'auto',
                        }} >
                        </Box >
                        <Typography gutterBottom variant="body1" component="div">
                            Figure 3. Contract Draft
                        </Typography>
                    </Grid>
                    <Grid item >
                        <Box component='img' src={cmsUI3} sx={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                            objectFit: 'contain',
                            display: 'block',
                            margin: 'auto',
                        }} >
                        </Box >
                        <Typography gutterBottom variant="body1" component="div">
                            Figure 4. Contact Upload
                        </Typography>
                    </Grid>

                    <Grid item>
                        <Typography gutterBottom variant="h6" sx={{ mb: 1, mt: 3 }}>
                            User Interface
                        </Typography>
                        <Typography gutterBottom variant="body1" sx={{ mb: 1, mx: 2 }}>
                            The user interface (UI) of the eCSRM system plays a vital role in ensuring user-friendliness,
                            intuitive navigation, and efficient interaction with the system's functionalities. Please refer to
                            Annex 6 for examples of the proof of concept UI.
                        </Typography>
                        <List>
                            <ListItem>
                                ● Dashboard Home Page: The system features a comprehensive dashboard providing an overview of key metrics, alerts, and notifications. Graphical visualizations represent contract performance, delivery status, and deadlines, enabling quick understanding and action.
                            </ListItem>
                            <ListItem>
                                ● Contract Drafting and Management: The user-friendly interface allows for drafting, updating, and managing contracts. Templates for one-off and framework contracts ensure familiarity. Essential fields, such as tender numbers, quantities, and prices, are easily populated.
                            </ListItem>
                            <ListItem>
                                ● Contract Signing and Document Sharing: The system enables electronic document sharing for e-signature by external parties. MoH has limited access for e-signing purposes. In-person signing appointments can be scheduled, and signed contracts can be e-filed.
                            </ListItem>
                            <ListItem>
                                ● Purchase Order Management: Users can approve electronic GPOs, import and e-sign GPOs, and exchange e-signed GPOs with stakeholders. A comprehensive contract database is maintained and updated.
                            </ListItem>
                            <ListItem>
                                ● Performance Evaluation and Metrics: A performance dashboard includes alerts and reminders for delivery time, late delivery, and contract expiration dates.
                            </ListItem>
                            <ListItem>
                                ● Web Server Infrastructure: The system utilizes a scalable and reliable on-premises web server infrastructure. Load balancing, fault tolerance, and high availability considerations ensure uninterrupted access.
                            </ListItem>
                            <ListItem>
                                ● Write API: The system implements a write API for contract actions and help desk ticket logging. It interacts with the relational database, storing contract data, line items, tickets, and user information. The write API ensures data integrity, security, and handles file uploads to the object store for signed contracts.
                            </ListItem>
                            <ListItem>
                                ● Read API: The system includes a read API for retrieving contract data, line items, tickets, and user details from the database. It facilitates report generation, dashboard analytics, and access to raw contract files and activity logs stored in the object store for auditing purposes.
                            </ListItem>
                        </List>



                        <Typography gutterBottom variant="h6" sx={{ mb: 1, mt: 3 }}>
                            Alerting Services
                        </Typography>
                        <Typography gutterBottom variant="body1" sx={{ mb: 1, mx: 2 }}>
                            The system will incorporate robust alerting services to provide timely notifications and alerts to
                            users and administrators. This will ensure that they are promptly informed about important
                            events, changes, or issues within the system.
                        </Typography>
                        <List>
                            <ListItem>
                                ● Event Triggers: The alerting services monitor specific system events, such as contract expiry or help desk ticket submissions, generating notifications when triggered.
                            </ListItem>
                            <ListItem>
                                ● Notification Channels: Multiple channels, including email, SMS, and push notifications, are supported by the alerting services, ensuring users receive alerts through their preferred communication methods.
                            </ListItem>
                            <ListItem>
                                ● Escalation Mechanism: To address unattended alerts or critical issues, the alerting services incorporate an escalation mechanism, escalating alerts to designated individuals or teams for timely resolution and preventing bottlenecks.
                            </ListItem>
                        </List>

                        <Typography gutterBottom variant="h6" sx={{ mb: 1, mt: 3 }}>
                            Database Architecture
                        </Typography>
                        <Typography gutterBottom variant="body1" sx={{ mb: 1, mx: 2 }}>
                            The eCSRM system will employ a robust database architecture to ensure efficient data
                            management, storage, and retrieval. The database will serve as the central repository for
                            contract data, line item information, user details, help desk tickets, and other relevant system
                            data.
                        </Typography>
                        <List>
                            <ListItem>
                                ● Relational Database: The system will use a relational database management system (RDBMS), like PostgreSQL, to store structured data in a tabular format, providing flexibility, data integrity, and robust querying capabilities.
                            </ListItem>
                            <ListItem>
                                ● Database Schema: An appropriate schema will be designed to represent entities, relationships, and attributes relevant to the system. Tables will store contracts, line items, help desk tickets, user information, and other required data.
                            </ListItem>
                            <ListItem>
                                ● Data Integrity and Security: The database will enforce data integrity through constraints such as primary keys, foreign keys, unique constraints, and check constraints. Access controls and security measures will be implemented to protect sensitive data and prevent unauthorized access.
                            </ListItem>
                            <ListItem>
                                ● Indexing and Query Optimization: Indexes will be created on frequently queried columns to enhance query performance. Query optimization techniques, like query rewriting and join optimization, will be employed for resource-intensive queries, ensuring optimal performance.
                            </ListItem>
                            <ListItem>
                                ● Backup and Restore: Regular database backups will be performed to prevent data loss and support disaster recovery. Backup strategies, such as full or incremental backups, will be implemented based on recovery objectives. Backup files will be securely stored and can be restored in case of data corruption or system failures.
                            </ListItem>
                        </List>

                        <Typography gutterBottom variant="h6" sx={{ mb: 1, mt: 3 }}>
                            Object Store
                        </Typography>
                        <Typography gutterBottom variant="body1" sx={{ mb: 1, mx: 2 }}>
                            The eCSRM system will utilize an object store to store and manage various files and documents
                            associated with the contracts, such as signed contract files, attachments, or supporting
                            documents.
                        </Typography>
                        <List>
                            <ListItem>
                                ● File Storage: The object store serves as a scalable and durable repository for storing files in their original formats, like PDFs, Word documents, or Excel spreadsheets. Users, including staff and suppliers, can upload, retrieve, and manage files through the system.
                            </ListItem>
                            <ListItem>
                                ● Scalability and Durability: The object store scales horizontally to handle increasing data volumes without impacting performance.
                            </ListItem>
                            <ListItem>
                                ● Access Control: Granular access controls regulate user permissions, restricting file access based on roles or privileges.
                            </ListItem>
                            <ListItem>
                                ● Integration with Web Server: The web server and APIs seamlessly interact with the object store, enabling file upload, retrieval, metadata updates, and version management within the eCSRM system.
                            </ListItem>
                            <ListItem>
                                ● Backup and Redundancy: The object store may implement backup and redundancy measures to protect against data loss and system failures.
                            </ListItem>
                        </List>

                    </Grid>
                </Grid >
            </Container >

        </>
    );
}

export default ContractManagementSystem;