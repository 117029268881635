import {
    Typography,
    Divider,
    List,
    ListItemButton,
    Container,
} from "@mui/material";
import { useNavigate } from 'react-router-dom';

import { news } from '../constants';

export default function Sidebar() {
    const navigate = useNavigate();

    return (
        <Container sx={{ my: 3 }}>
            <Typography variant="subtitle1" gutterBottom>
                News
            </Typography>
            <Divider />
            <List sx={{}} >
                {news.map((newsItem, index) => (
                    <ListItemButton key={index} onClick={() => { navigate(newsItem.route) }}>
                        <Typography variant="body2" gutterBottom style={{
                            color: 'blue',
                            textDecoration: 'underline',
                            cursor: 'pointer',
                        }}>
                            {newsItem.name}
                        </Typography>
                    </ListItemButton>
                ))}
            </List>
        </Container>
    );
}