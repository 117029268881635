import {
    Typography,
    Container,
    Grid,
    Paper,
    Divider,
    Button,
    Box,
} from "@mui/material";
import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import SecurityIcon from '@mui/icons-material/Security';
import CodeIcon from '@mui/icons-material/Code';
import BarChart from '@mui/icons-material/BarChart';
import Web from '@mui/icons-material/Web';
import SmartToyIcon from '@mui/icons-material/SmartToy';
import { motion } from "framer-motion";
import { animated, useTrail } from '@react-spring/web'
import Carousel from 'react-bootstrap/Carousel';

import consulting from "../assets/consulting.jpg";
import CarouselItemImg from "../components/carousel_item_img";

const cardContainer = {
    offscreen: {
    },
    onscreen: {
        transition: {
            staggerChildren: 0.5
        }
    }
};

const cardVariants = {
    offscreen: {
        y: 300,
        opacity: 0,
    },
    onscreen: {
        y: 0,
        opacity: 1,
        transition: {
            type: "spring",
            bounce: 0.4,
            duration: 0.8
        }
    }
};

export default function Home() {
    React.useEffect(() => {
        document.title = "Home";
        window.scrollTo(0, 0);
        const metaDescriptionElement = document.querySelector('meta[name="description"]') as HTMLMetaElement | null;
        if (metaDescriptionElement) {
            metaDescriptionElement.content = `ULTech LLC, a leading IT consulting firm specializing 
            in providing innovative and tailored technology solutions for businesses.`;
        }
    }, []);

    const theme = useTheme();
    const navigate = useNavigate();

    const trailSprings = useTrail(5, {
        from: {
            transform: "translateY(-200px)",
            opacity: 0,
        },
        to: {
            transform: "translateY(0px)",
            opacity: 1,
        }
    });

    return (
        <>
            <Container sx={{ my: 3 }}>
                <Box mb={3}>
                    <Carousel >
                        <Carousel.Item interval={3000}>
                            <CarouselItemImg pic={consulting} title="Where Inspiration and Possibilities Await"></CarouselItemImg>
                        </Carousel.Item>
                    </Carousel>
                </Box>

                <Box sx={{ mt: 3, backgroundColor: theme.palette.background.paper, p: 5 }} >
                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={5} >
                        <Grid item sx={{ textAlign: 'center' }}>
                            <Typography variant="h4" gutterBottom>
                                What We Do?
                            </Typography>
                        </Grid>
                    </Grid>


                    <Grid
                        container
                        direction="row"
                        justifyContent="space-evenly"
                        alignItems="center"
                        spacing={5} sx={{
                            my: 3
                        }}>

                        <Grid item sx={{ textAlign: 'center' }}>
                            <animated.div style={trailSprings[0]}>
                                <Paper elevation={1} sx={{
                                    p: 2,
                                    backgroundColor: theme.palette.background.default,
                                    width: 300,
                                    height: 100,
                                }}>
                                    <SecurityIcon />
                                    <Typography variant="h5">
                                        Cybersecurity
                                    </Typography>
                                </Paper>
                            </animated.div>
                        </Grid>

                        <Grid item sx={{ textAlign: 'center' }}>
                            <animated.div style={trailSprings[1]}>
                                <Paper elevation={1} sx={{
                                    p: 2,
                                    backgroundColor: theme.palette.background.default,
                                    width: 300,
                                    height: 100,
                                }}>
                                    <BarChart />
                                    <Typography variant="h5">
                                        Data Analytics
                                    </Typography>
                                </Paper>
                            </animated.div>
                        </Grid>
                        <Grid item sx={{ textAlign: 'center' }}>
                            <animated.div style={trailSprings[2]}>
                                <Paper elevation={1} sx={{
                                    p: 2,
                                    backgroundColor: theme.palette.background.default,
                                    width: 300,
                                    height: 100,
                                }}>
                                    <Web />
                                    <Typography variant="h5">
                                        Website Development
                                    </Typography>
                                </Paper>
                            </animated.div>
                        </Grid>
                        <Grid item sx={{ textAlign: 'center' }}>
                            <animated.div style={trailSprings[3]}>
                                <Paper elevation={1} sx={{
                                    p: 2,
                                    backgroundColor: theme.palette.background.default,
                                    width: 300,
                                    height: 100,
                                }}>
                                    <SmartToyIcon />
                                    <Typography variant="h5">
                                        Artificial Intelligence
                                    </Typography>
                                </Paper>
                            </animated.div>
                        </Grid>
                        <Grid item sx={{ textAlign: 'center' }}>
                            <animated.div style={trailSprings[4]}>
                                <Paper elevation={1} sx={{
                                    p: 2,
                                    backgroundColor: theme.palette.background.default,
                                    width: 300,
                                    height: 100,
                                }}>
                                    <CodeIcon />
                                    <Typography variant="h5">
                                        App Development
                                    </Typography>
                                </Paper>
                            </animated.div>
                        </Grid>
                    </Grid>

                    <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        spacing={5} sx={{
                            my: 3
                        }}>
                        <Grid item sx={{ textAlign: 'center' }}>
                            <Button
                                variant="contained"
                                size="large"
                                onClick={() => { navigate("/s/capability") }}
                            >
                                More Capabilities
                            </Button>
                        </Grid>
                    </Grid>
                </Box >


                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center"
                    spacing={5} sx={{
                        mt: 3
                    }}>

                    <Grid item sx={{ textAlign: 'center' }}>
                        <Typography variant="h4" gutterBottom>
                            Why Choose Us?
                        </Typography>
                    </Grid>

                </Grid>

                <motion.div
                    className="card-container"
                    variants={cardContainer}
                    initial="offscreen"
                    whileInView="onscreen"
                    viewport={{ once: true, amount: 0.8 }}
                >
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-evenly"
                        alignItems="center"
                        spacing={5} sx={{
                            mt: 3
                        }}>

                        <Grid item sx={{ textAlign: 'center' }}>
                            <motion.div className="card" variants={cardVariants}>
                                <Paper elevation={1} sx={{
                                    p: 2,
                                    width: 300,
                                    height: 200, // Set the fixed height
                                    display: "flex",
                                    flexDirection: "column",

                                }}>
                                    <Typography variant="h5">
                                        Best Value
                                    </Typography>
                                    <Divider sx={{ my: 3 }} />
                                    <Typography variant="body1">
                                        ULTech has a high efficiency operation business model so we can pass the best values to our customers.
                                    </Typography>
                                </Paper>
                            </motion.div>
                        </Grid>


                        <Grid item sx={{ textAlign: 'center' }}>
                            <motion.div className="card" variants={cardVariants}>
                                <Paper elevation={1} sx={{
                                    p: 2,
                                    width: 300,
                                    height: 200, // Set the fixed height
                                    display: "flex",
                                    flexDirection: "column",
                                }}>
                                    <Typography variant="h5">
                                        Customer Satisfaction
                                    </Typography>
                                    <Divider sx={{ my: 3 }} />
                                    <Typography variant="body1">
                                        ULTech is obssessed with customer satisfaction and we want to develop long-term partnership with you.
                                    </Typography>
                                </Paper>
                            </motion.div>
                        </Grid>



                        <Grid item sx={{ textAlign: 'center' }}>
                            <motion.div className="card" variants={cardVariants}>
                                <Paper elevation={1} sx={{
                                    p: 2,
                                    width: 300,
                                    height: 200, // Set the fixed height
                                    display: "flex",
                                    flexDirection: "column",
                                }}>
                                    <Typography variant="h5">
                                        Expertise
                                    </Typography>
                                    <Divider sx={{ my: 3 }} />
                                    <Typography variant="body1">
                                        ULTech has the best talents in the IT field and we know what we are doing.
                                    </Typography>
                                </Paper>
                            </motion.div>
                        </Grid>
                    </Grid>
                </motion.div>


                <Grid
                    container
                    direction="row"
                    justifyContent="space-evenly"
                    alignItems="center"
                    spacing={5} sx={{
                        my: 3
                    }}>
                    <Grid item >
                        <Button
                            variant="contained"
                            size="large"
                            onClick={() => { navigate("/s/showcase") }}
                        >
                            Showcase
                        </Button>
                    </Grid>
                    <Grid item >
                        <Button
                            variant="contained"
                            size="large"
                            onClick={() => { navigate("/s/contact") }}
                        >
                            Contact Us
                        </Button>
                    </Grid>
                </Grid>
            </Container >
        </>
    );
}
