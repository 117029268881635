import * as React from 'react';
import {
    Box,
    Typography,
    ListItem,
    List,
    Grid,
    Container,
} from "@mui/material";
import vul from "../../assets/capability/vulnerability_management.png";

const Cybersecurity = () => {
    React.useEffect(() => {
        document.title = "Cybersecurity";
        window.scrollTo(0, 0);
        const metaDescriptionElement = document.querySelector('meta[name="description"]') as HTMLMetaElement | null;
        if (metaDescriptionElement) {
            metaDescriptionElement.content = `At ULTech, our cybersecurity capabilities provide comprehensive protection for organizations by implementing robust security measures, proactive threat detection, and incident response strategies to safeguard critical assets and mitigate risks in an ever-evolving digital landscape.`;
        }
    }, []);

    return (
        <>

            <Container sx={{ my: 3 }}>
                <Grid container
                    spacing={3} sx={{
                        my: 3
                    }}>
                    <Grid item >
                        <Typography gutterBottom variant="h5" component="div">
                            Cybersecurity
                        </Typography>
                        <Typography gutterBottom variant="body1" sx={{ mb: 1, mx: 2 }}>
                            ULTech possesses robust cybersecurity capabilities, providing comprehensive solutions to safeguard organizations from evolving threats. Our expertise encompasses areas such as network security, data protection, threat intelligence, incident response, and risk management. We utilize advanced technologies and industry best practices to design and implement tailored security measures that align with our clients' unique requirements and compliance standards.
                        </Typography>
                        <Typography gutterBottom variant="body1" sx={{ mb: 1, mx: 2 }}>
                            Our proactive approach includes conducting thorough security assessments, identifying vulnerabilities, and implementing robust security controls to mitigate risks. We prioritize continuous monitoring, detection, and response to potential threats, ensuring the confidentiality, integrity, and availability of critical data and systems. By staying abreast of emerging trends and leveraging our deep knowledge in cybersecurity, we provide our clients with the peace of mind and confidence that their digital assets and sensitive information are safeguarded against cyber threats.
                        </Typography>
                    </Grid>
                    <Grid item >
                        <Typography gutterBottom variant="h5" component="div">
                            Vulnerability Management
                        </Typography>
                        <Typography gutterBottom variant="body1" sx={{ mb: 1, mx: 2 }}>
                            Our company specializes in vulnerability management reporting and process improvement. With a dedicated focus on cybersecurity, we understand the criticality of identifying and addressing vulnerabilities in a timely manner. Our team has experience in conducting comprehensive vulnerability assessments, analyzing findings, and generating detailed reports that highlight vulnerabilities and their potential impact. By leveraging industry-standard tools and frameworks, we provide our clients with actionable insights to mitigate risks and strengthen their security posture.
                        </Typography>

                    </Grid>

                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Box component='img' src={vul} sx={{
                            maxWidth: '100%',
                            maxHeight: '100%',
                            objectFit: 'contain',
                            display: 'block',
                            margin: 'auto',
                        }} >
                        </Box >
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <List sx={{
                            listStyleType: 'decimal',
                        }} component="nav">
                            <Typography gutterBottom variant="h5" component="div">
                                Vulnerability Management Process
                            </Typography>
                            <Typography gutterBottom variant="body1" sx={{ mb: 1, mx: 2 }}>
                                Our company employs a robust vulnerability management process that follows a comprehensive Discover, Assess, Report, Remediate, and Verify cycle. This approach ensures thorough identification, evaluation, and mitigation of vulnerabilities to maintain a strong security posture.
                            </Typography>
                            <ListItem divider sx={{ display: 'list-item' }}>
                                Discover: Utilize advanced scanning tools and techniques to identify vulnerabilities across systems, networks, and applications, including automated scans, manual testing, and threat intelligence sources.
                            </ListItem>
                            <ListItem divider sx={{ display: 'list-item' }}>
                                Assess: Analyze identified vulnerabilities to determine severity, potential impact, and likelihood of exploitation, providing clients with a clear understanding of risks through rigorous analysis and risk prioritization.
                            </ListItem>
                            <ListItem divider sx={{ display: 'list-item' }}>
                                Report: Generate detailed reports outlining vulnerabilities, associated risks, and recommended mitigation actions, ensuring clarity, conciseness, and actionable insights for informed decision-making.
                            </ListItem>
                            <ListItem divider sx={{ display: 'list-item' }}>
                                Remediate: Work closely with clients to develop and implement effective remediation strategies, such as applying security patches, configuring settings, implementing controls, and addressing vulnerabilities through various means to minimize exploitation risks.
                            </ListItem>
                            <ListItem sx={{ display: 'list-item' }}>
                                Verify: Conduct thorough testing and validation to confirm the successful mitigation of vulnerabilities, utilizing techniques like penetration testing and vulnerability re-scans to ensure the effectiveness of remediation efforts.
                            </ListItem>
                        </List>
                    </Grid>
                </Grid >
            </Container>

        </>
    );
}

export default Cybersecurity;