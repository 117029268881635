import * as React from 'react';
import {
    Container,
    Grid,
    Paper,
    Typography,
    List,
    ListItem,
    ListItemText,
    Box,
} from "@mui/material";
import cameraroll from "../assets/camera_roll.jpg";
import naicsLogo from "../assets/NAICS_logo.png";
import Hero from '../components/hero';
import { motion } from "framer-motion";


const cardVariants = {
    offscreen: {
        y: 300,
        opacity: 0,

    },
    onscreen: {
        y: 0,
        opacity: 1,
        transition: {
            type: "spring",
            bounce: 0.4,
            duration: 0.8
        }
    }
};

const cardContainer = {
    offscreen: {
    },
    onscreen: {
        transition: {
            staggerChildren: 0.5
        }
    }
};



export default function About() {
    //const { contact } = useLoaderData();
    React.useEffect(() => {
        document.title = "About";
        window.scrollTo(0, 0);
        const metaDescriptionElement = document.querySelector('meta[name="description"]') as HTMLMetaElement | null;
        if (metaDescriptionElement) {
            metaDescriptionElement.content = `Discover Our Story. Learn about our mission, values, 
            and the passion that drives us to deliver exceptional services and solutions to our clients.`;
        }
    }, []);

    return (
        <>
            <Container sx={{ my: 3 }}>
                <Hero pic={cameraroll} title="Unveiling Our Story" />
                <Grid container sx={{ mt: 3 }} spacing={3}>
                    <Grid item lg={12} md={12} sm={12} xs={12} >
                        <Paper elevation={0} sx={{ p: 2 }}>
                            <Typography gutterBottom variant="h5">
                                Our Company
                            </Typography>
                            <Typography gutterBottom variant="body1">
                                Founded in 2022, ULTech is a provider of state-of-the-art information technology products and services that support federal,
                                state, and local government. ULTech is highly customer-focused, goal-oriented, and quality-driven. We are committed to using
                                industry best practices to provide customers with tailored solutions to achieve operational goals and exceed expectations.
                            </Typography>
                            <Typography gutterBottom variant="h5" mt={5}>
                                Our Mission
                            </Typography>
                            <Typography gutterBottom variant="body1">
                                ULTech's mission is to deliver cutting-edge technology and engineering solutions to our clients while:
                            </Typography>
                            <List sx={{}} component="nav">
                                <ListItem >
                                    <ListItemText primary="1. Tackling intricate technical challenges in ways that bring advantages to the general public." />
                                </ListItem>
                                <ListItem >
                                    <ListItemText primary="2. Delivering quality services and unbiased customized solutions to our clients." />
                                </ListItem>
                                <ListItem >
                                    <ListItemText primary="3. Building partnership with our clients by providing them tailored technical solutions that fit theirneeds." />
                                </ListItem>
                                <ListItem >
                                    <ListItemText primary="4. Conducting business with a commitment to maintaining exemplary levels of honesty, fairness, and integrity in all business operations" />
                                </ListItem>
                                <ListItem >
                                    <ListItemText primary="5. Fostering employee development through continuous learning and facilitating access to the latest knowledge and advancements in the field." />
                                </ListItem>
                            </List>
                        </Paper>


                        <Typography gutterBottom variant="h5" mt={5}>
                            Our Values
                        </Typography>

                        <motion.div
                            className="card-container"
                            initial="offscreen"
                            whileInView="onscreen"
                            variants={cardContainer}
                            viewport={{ once: true, amount: 0.8 }}
                        >
                            <Grid container alignItems="stretch" spacing={2}>

                                <Grid item xs={12} sm={4}>
                                    <motion.div className="card" variants={cardVariants}>
                                        <Paper elevation={3} sx={{ p: 2 }}>
                                            <List>
                                                <ListItem>
                                                    <ListItemText primary="Ethical" />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemText primary="Innovative" />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemText primary="Efficient" />
                                                </ListItem>
                                            </List>
                                        </Paper>
                                    </motion.div>
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <motion.div className="card" variants={cardVariants}>
                                        <Paper elevation={3} sx={{ p: 2 }}>
                                            <List>
                                                <ListItem>
                                                    <ListItemText primary="Commitment to Excellence" />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemText primary="Customer Oriented" />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemText primary="Integrity and Value Based" />
                                                </ListItem>
                                            </List>
                                        </Paper>
                                    </motion.div>
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <motion.div className="card" variants={cardVariants}>
                                        <Paper elevation={3} sx={{ p: 2 }}>
                                            <List>
                                                <ListItem>
                                                    <ListItemText primary="Collaborative Company Culture" />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemText primary="Individual Contributions" />
                                                </ListItem>
                                                <ListItem>
                                                    <ListItemText primary="Research and Development" />
                                                </ListItem>
                                            </List>
                                        </Paper>
                                    </motion.div>
                                </Grid>

                            </Grid>
                        </motion.div>
                    </Grid>

                    <Grid item lg={6} md={6} sm={12} xs={12}>
                        <Box component='img' src={naicsLogo} sx={{
                            maxHeight: '400px',
                            maxWidth: '400px,',
                            objectFit: 'contain',
                            display: 'block',
                            mx: 'auto',
                        }}></Box>
                    </Grid>
                    <Grid item lg={6} md={6} sm={12} xs={12} sx={{}}>
                        <Paper elevation={0} sx={{ p: 2, }}>
                            <Typography gutterBottom variant="h5">
                                NAICS Codes
                            </Typography>
                            <List sx={{}} component="nav">
                                <ListItem divider>
                                    <ListItemText primary="518210 - Computer Infrastructure Providers, Data Processing, Web Hosting, And Related Services" />
                                </ListItem>
                                <ListItem divider>
                                    <ListItemText primary="513210 - Software Publisher" />
                                </ListItem>
                                <ListItem divider>
                                    <ListItemText primary="519290 - Web Search Portals And All Other Information Services" />
                                </ListItem>
                                <ListItem divider>
                                    <ListItemText primary="541511 - Custom Computer Programming Services" />
                                </ListItem>
                                <ListItem divider>
                                    <ListItemText primary="541512 - Computer Systems Design Services" />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="541519 - Other Computer Related Services" />
                                </ListItem>
                            </List>
                        </Paper>

                    </Grid>
                    <Grid item lg={6} md={6} sm={6} xs={12} sx={{}}>
                        <Paper elevation={0} sx={{ p: 2 }}>
                            <Typography gutterBottom variant="h5">
                                Socio-Economic Types
                            </Typography>
                            <List sx={{}} component="nav">
                                <ListItem divider>
                                    <ListItemText primary="Small Disadvantaged Business" />
                                </ListItem>
                                <ListItem divider>
                                    <ListItemText primary="Woman Owned Small Business" />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="Minority Owned Business" />
                                </ListItem>
                            </List>
                        </Paper>

                    </Grid>

                    <Grid item lg={6} md={6} sm={6} xs={12} sx={{}}>
                        <Paper elevation={0} sx={{
                            p: 2,
                            mb: 2
                        }}>

                            <Typography gutterBottom variant="h5">
                                Other Info
                            </Typography>
                            <List sx={{}} component="nav">
                                <ListItem divider>
                                    <ListItemText primary="UEI: D9WZWV4JMR33" />
                                </ListItem>
                                <ListItem divider>
                                    <ListItemText primary="CAGE: 9KE77" />
                                </ListItem>
                                <ListItem>
                                    <ListItemText primary="DUNS: 115109321" />
                                </ListItem>
                            </List>
                        </Paper>
                    </Grid>
                </Grid >
            </Container >
        </>
    );
}