import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { useMediaQuery, useTheme } from '@mui/material';
import ListSubheader from '@mui/material/ListSubheader';
import { Brightness4, Brightness7 } from '@mui/icons-material';
import { motion } from "framer-motion";

// import ultechlogoblack from "../assets/ULTech_logos_black.png";
import ultechlogowhite from "../assets/ULTech_logos_white.png";
import { ColorModeContext } from '../App';
import Footer from './footer';
import { navItems } from '../constants';

const motionContainer = {
  hidden: {
    opacity: 0,
  },
  show: {
    opacity: 1,
    transition: {
      staggerChildren: 0.15
    }
  }
}

const motionItem = {
  hidden: {
    x: "-100%",
    opacity: 0,
  },
  show: {
    x: "0",
    opacity: 1,
    transition: {
      duration: 0.5,
    }
  }
}

const drawerWidth = 240;

interface DrawerAppBarProps {
  window?: () => Window;
  main: React.ReactElement;
  sidebar: React.ReactElement;
}

function DrawerAppBar(props: DrawerAppBarProps) {
  const { window } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const navigate = useNavigate();
  const colorMode = React.useContext(ColorModeContext);
  const container = window !== undefined ? () => window().document.body : undefined;


  const handleDrawerClick = (myRoute: string) => {
    if (isMobile) {
      setMobileOpen((prevState) => !prevState);
    }
    navigate(myRoute);
  };

  const drawer = (
    <Box>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <img
          // src={theme.palette.mode === 'light' ? ultechlogoblack : ultechlogowhite}
          src={ultechlogowhite}
          alt="ULTech Logo"
          onClick={() => navigate('/')}
          style={{ width: 100, height: 'auto', cursor: 'pointer' }}
        />
      </Box>
      <Divider />

      <List
        sx={{ width: '100%' }}
        component="nav"
        subheader={
          <ListSubheader component="div" id="nested-list-subheader">

          </ListSubheader>
        }
      >
        <motion.div variants={motionContainer}
          initial="hidden"
          animate="show"
        >
          {navItems.map((item, index) => (
            <motion.div
              key={index}
              variants={motionItem}
              whileHover={{
                scale: 1.1,
              }}>
              <ListItemButton onClick={() => handleDrawerClick(item.route)}>
                <ListItemIcon>
                  {item.icon ? (
                    item.icon
                  ) : (
                    <img src={item.pic} alt="Voco Icon" style={{ width: '24px', height: '24px' }} />
                  )}
                </ListItemIcon>
                <ListItemText primary={item.name} />
              </ListItemButton>
            </motion.div>
          ))}
        </motion.div>
      </List>
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}>
        <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === 'dark' ? <Brightness7 /> : <Brightness4 />}
        </IconButton>
      </Box>

    </Box >
  );

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar component="nav" color='inherit'>
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={() => setMobileOpen((prevState) => !prevState)}
            sx={{ mr: 2, display: { lg: 'none' } }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            variant="h6"
            component="div"
            sx={{ flexGrow: 1, }}
          >
            ULTech LLC
          </Typography>
          <Typography
            variant="body1"
            sx={{ textTransform: 'capitalize', }}
          >

          </Typography>

        </Toolbar>
      </AppBar>

      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={() => setMobileOpen((prevState) => !prevState)}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        sx={{
          display: { lg: 'none', md: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
      >
        {drawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { lg: 'block', xs: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
        open
      >
        {drawer}
      </Drawer>


      <Box component="main" sx={{
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
        ml: { lg: `${drawerWidth}px` },
        // mr: {/* xl: `${drawerWidth}px` */ },
      }} >
        <Toolbar />
        {//props.main
        }
        <Box sx={{ display: 'flex', flexDirection: 'row' }}>
          {props.main}
          <Box
            sx={{
              flex: '0 0 auto',
              width: `${drawerWidth}px`,
              display: { xs: 'none', xl: 'block', },
            }}
          >
            {props.sidebar}
          </Box>

        </Box>
        <Footer />
      </Box >
    </Box >
  );
}

DrawerAppBar.propTypes = {
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

export default DrawerAppBar;