import {
    Typography,
    Divider,
    Box,
    Container,
} from "@mui/material";


export default function Footer() {
    //const { contact } = useLoaderData();


    return (
        <Container sx={{ my: 3 }}>
            <Divider />
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                my: 3,
            }}>
                <Typography variant="body1" gutterBottom>
                    Email: <a href="mailto:contact@ultech.cloud">contact@ultech.cloud</a>
                </Typography>
                <Typography variant="body1" gutterBottom>
                    Address: 2302 Gallows Rd Unit 244, Dunn Loring, VA 22027
                </Typography>
                <Typography variant="body1" gutterBottom>
                    © 2022-2023 Copyright: ULTech LLC
                </Typography>
            </Box>
        </Container>
    );
}